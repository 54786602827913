// src/components/FormComponent.js
import React, { useState, useRef } from 'react'; // Add the 'useRef' import
import SignatureCanvas from 'react-signature-canvas'
import jsPDF from 'jspdf';
import xIcon from '../x_icon_150997.svg';

import './TomrerSJA.css';


const TomrerSJA = () => {
  // eslint-disable-next-line
  const [selectedSchema, setSelectedSchema] = useState('');// eslint-disable-next-line
  const [formData, setFormData] = useState([]);// eslint-disable-next-line
  const [riskAction, setRiskAction] = useState('');// eslint-disable-next-line
// eslint-disable-next-line
  const [riskLevel, setRiskLevel] = useState('');
  const [orderNumber, setOrderNumber] = useState('');// eslint-disable-next-line
  const [projectName, setProjectName] = useState('');
  const [timeFrame, setTimeFrame] = useState('');
  const [area, setArea] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [name, setName] = useState('');// eslint-disable-next-line
  const form = document.getElementById('form');
  const questionsSet1 = [
    'Er arbeidsområdet forsvarlig sikret og avgrenset?',
    'Er det behov for personlig verneutstyr (f.eks. hjelm, vernebriller, hørselsvern)?',
    'Er det nødvendig å utføre arbeidet med flere personer?',
    'Er det fare for fall fra høyde under utførelsen av oppdraget?',
    'Er elektrisk utstyr og verktøy i god stand og riktig brukt?',
    'Er det behov for spesielle sikkerhetstiltak ved bruk av sag eller annet kraftverktøy?',
    'Er det behov for å sikre området mot uautorisert tilgang?',
    'Er det nødvendig å ivareta ergonomiske forhold under arbeidet?',
    'Er det risiko knyttet til bruk av kjemikalier eller materialer?',
    'Er det nødvendig å sikre arbeidsområdet mot værforhold (regn, snø, vind)?',
    'Er det fare for støv- eller støyeksponering, og er tiltak satt inn for å begrense dette?',
    'Er det behov for spesiell opplæring eller sertifisering for å utføre oppdraget?',
    'Er det behov for å utføre sikkerhetssjekk av stillas eller stiger før bruk?',
    'Er det behov for brannsikkerhetstiltak på arbeidsstedet?',
    'Er det fare for klemming eller kutting av fingre/hender ved bruk av verktøy?',
    'Er det behov for å sikre arbeidsområdet mot uventet trafikk eller forstyrrelser?',
    'Er det nødvendig å ha tilgjengelig førstehjelpsutstyr på arbeidsstedet?',
    'Er det risiko for at materialer eller verktøy kan falle ned fra høyder?',
    'Er det behov for ekstra belysning på arbeidsstedet?',
    'Er det behov for å kommunisere spesielle forhold eller varsle andre på arbeidsstedet?',
];
// eslint-disable-next-line
const [currentQuestions, setCurrentQuestions] = useState(questionsSet1);
const [questionAnswers, setQuestionAnswers] = useState(Array(questionsSet1.length).fill(''));
const [questionRiskLevels, setQuestionRiskLevels] = useState(Array(questionsSet1.length).fill(''));
const [questionRiskActions, setQuestionRiskActions] = useState(Array(questionsSet1.length).fill(''));



const signatureRef = useRef(null);

const handleClearSignature = () => {
  if (signatureRef.current) {
    signatureRef.current.clear();
  }
};

const handleAnswerChange = (index, answer) => {
  const newQuestionAnswers = [...questionAnswers];
  newQuestionAnswers[index] = answer;
  setQuestionAnswers(newQuestionAnswers);
};

const handleRiskActionChange = (index, action) => {
  // Limit the input to a maximum of 225 symbols
  const limitedAction = action.slice(0, 127);

  const newQuestionRiskActions = [...questionRiskActions];
  newQuestionRiskActions[index] = limitedAction;
  setQuestionRiskActions(newQuestionRiskActions);
};
const handleRiskLevelChange = (index, level) => {
  const newQuestionRiskLevels = [...questionRiskLevels];
  newQuestionRiskLevels[index] = level;
  setQuestionRiskLevels(newQuestionRiskLevels);
};


const handleFormSubmit = async (e) => {
  e.preventDefault();

  // Create a PDF document
  const pdfBlob = await generatePdf();

  // Create a download link
  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(pdfBlob);
  downloadLink.download = 'generated.pdf';

  // Trigger the download
  downloadLink.click();
};

const generatePdf = async () => {
  const pdf = new jsPDF();// eslint-disable-next-line
  const pageHeight = pdf.internal.pageSize.height;
  const defaultFontSize = 8; // Set your default font size here
  const lineColor = 200; // Set the color of the lines (0-255)

  pdf.setFontSize(defaultFontSize);
  pdf.setDrawColor(lineColor);

  // Add form data to the PDF
  pdf.text(`Ordrenummer/Prosjektnavn: ${orderNumber}`, 15, 10);
  pdf.text(`Tidsrom: ${timeFrame}`, 15, 15);
  pdf.text(`Område: ${area}`, 15, 20);
  pdf.text(`Beskrivelse av oppdrag: ${description}`, 15, 25);

  // Draw horizontal lines
  let horizontalLineY = 30;

  // Draw vertical lines
  pdf.setDrawColor(50); // Set a darker color intensity (0-255)

  pdf.line(164, 30, 164, 290);
  pdf.line(180, 30, 180, 290);
  pdf.line(200, 30, 200, 290);
  pdf.line(5, 30, 5, 290);
  // Add signature image
  const signatureImage = await signatureToImage();
  pdf.addImage(signatureImage, 'PNG', 100, 5, 40, 20);

  // Add questions and answers
  const gridStart = 30;
  const gridSpacing = 6;
  currentQuestions.forEach((question, index) => {
    const questionY = gridStart + index * gridSpacing;

    // Draw horizontal lines between questions
    horizontalLineY = questionY + gridSpacing;
    pdf.line(5, horizontalLineY - 3.8, 200, horizontalLineY - 3.8);

    pdf.text(`${index + 1}. ${question}`, 5, questionY + 5);
    pdf.text(` ${questionRiskLevels[index]}`, 185, questionY + 5);
    pdf.text(` ${questionAnswers[index]}`, 164, questionY + 5);
    pdf.text(` ${questionRiskActions[index]}`, 5, questionY + 8);


    
  });

  // Add additional form data
  pdf.text(`Navn: ${name}`, 160, 20);
  pdf.text(`Dato: ${date}`, 160, 25);
  pdf.text(`Svar`, 167, 30);
  pdf.text(`Risikograd `,183 , 30);

  return pdf.output('blob');
};


const signatureToImage = async () => {
  const canvas = signatureRef.current.getCanvas();
  return canvas.toDataURL('image/png');
};

return (
  <form onSubmit={handleFormSubmit} id="form">
      <h1>Tømmrer Sikker Jobb Analyse</h1>
    <label>Ordrenummer/Prosjektnavn:</label>
    <input type="text" value={orderNumber} onChange={(e) => setOrderNumber(e.target.value)} />

    <label>Tidsrom:</label>
    <input type="text" value={timeFrame} onChange={(e) => setTimeFrame(e.target.value)} />

    <label>Område:</label>
    <input type="text" value={area} onChange={(e) => setArea(e.target.value)} />

    <label>Beskrivelse av oppdrag:</label>
    <textarea value={description} onChange={(e) => setDescription(e.target.value)} />
    <table>
        <thead>
          <tr>
            <th>Spørsmål</th>
            <th>Svaralternativ</th>
            <th>Risikograd</th>
          </tr>
        </thead>
        <tbody>
        {currentQuestions.map((question, index) => (
  <React.Fragment key={index}>
    <tr>
      <td>
        <label>{`${index + 1}. ${question}`}</label>
      </td>
      <td>
        <select
          value={questionAnswers[index]}
          onChange={(e) => handleAnswerChange(index, e.target.value)}
        >
          <option value="Ja">Ja</option>
          <option value="Nei">Nei</option>
          <option value="Ikke aktuelt">Ikke aktuelt</option>
        </select>
      </td>
      <td>
        <select
          value={questionRiskLevels[index]}
          onChange={(e) => handleRiskLevelChange(index, e.target.value)}
        >
          <option value="Liten">Liten</option>
          <option value="Medium">Medium</option>
          <option value="Stor">Stor</option>
        </select>
      </td>
    </tr>
    <tr>
      <td colSpan="3">
        <label>Risikotiltak</label>
        <textarea
          value={questionRiskActions[index]}
          onChange={(e) => handleRiskActionChange(index, e.target.value)}
        />
      </td>
    </tr>
  </React.Fragment>
))}
    

    <tr>
      <td colSpan="3">
        <label>Signatur her:</label>
        <div style={{ position: 'relative', width: '100%' }}>
          <SignatureCanvas
            ref={signatureRef}
            penColor="black"
            canvasProps={{
              className: 'sigContainer',
            }}
          />
          {signatureRef.current && (
            <img
              src={xIcon}
              alt="Clear Signature"
              onClick={handleClearSignature}
              style={{
                position: 'absolute',
                top: '15px',
                right: '5px',
                width: '20px',
                height: '20px',
                cursor: 'pointer',
              }}
            />
          )}
          <label>
            Name:
            <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
          </label>
          <label>Dato ved signatur:</label>
          <input type="text" value={date} onChange={(e) => setDate(e.target.value)} />
        </div>
      </td>
    </tr>
    </tbody>
      </table>

    <button type="submit">Lag skjema</button>
  </form>
);
};

export default TomrerSJA;
import React, { useState } from 'react';
import GruverSJA from './components/GruverSJA';
import ElektroSJA from './components/ElektroSJA';
import TomrerSJA from './components/TomrerSJA';

function App() {
  const [selectedSheet, setSelectedSheet] = useState('Elektro'); // Default to ElektroSJA

  const handleSheetChange = (event) => {
    setSelectedSheet(event.target.value);
  };

  return (
    <div className="App">
      <h1>Skjemaapplikasjon</h1>

      <label htmlFor="sheetSelector">Velg ark:</label>
      <select id="sheetSelector" value={selectedSheet} onChange={handleSheetChange}>
        <option value="Gruver">Sikker jobb Analyse Gruve</option>
        <option value="Elektro">Sikker jobb Analyse Elektro</option>
        <option value="TomrerSJA">Sikker jobb Analyse Tømrer</option>
      </select>

      {selectedSheet === 'Gruver' ? <GruverSJA /> : selectedSheet === 'Elektro' ? <ElektroSJA /> : <TomrerSJA />}
    </div>
  );
}

export default App;
